import React, {useEffect} from 'react';
import { Box, styled, ThemeProvider } from '@mui/system';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll'

const FooterLink = styled(Link)(({ theme }) => ({
    color: theme.palette.primary.light,
    fontFamily: theme.typography.primary.fontFamily,
    textTransform: 'uppercase', 
    fontWeight: 800,
    [theme.breakpoints.up("xs")]: {
        paddingRight: theme.spacing(6),
        fontSize: '0.875rem',
        
    },
    [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(0),
    },
  }));


export default function Footer(props) {
    const clickedNavButton = (el) => {
        console.log("Clicked navbutton " + el);
    }

    return (
            <Box sx={[{
                width: '100%',
                backgroundColor: (theme) => theme.palette.primary.main,
                position: 'fixed',
                bottom: '0px',
            }
            ]}>
                    <ScrollContainer className="scroll-container"
                        vertical="false"
                        style={{
                        height: '100%',
                        width: '100%',
                        top: '0',
                        }}
                >
                <Box sx={{
         
                }}>
                    <Box sx={{
                        paddingRight: (theme) => theme.spacing(3),
                        paddingTop: ['2px', '1px'],
                        paddingBottom: ['2px', '1px'],
                        display: { xs: 'block', md: 'flex'},
                        justifyContent: 'flex-end',
                        gap: (theme) => theme.spacing(5),
                        textAlign: 'right',
                        overflowX: 'visible',
                        whiteSpace: 'nowrap',           
                    }}>
                        <FooterLink component={RouterLink} to="/contact">Contact</FooterLink>
                        <FooterLink href="https://vimeo.com/channels/barbaraherold">Vimeo</FooterLink>
                        <FooterLink href="https://instagram.com/barb.herold">Insta</FooterLink>
                        <FooterLink component={RouterLink} to="/imprint">Imprint/Policy</FooterLink>
                    </Box>

                </Box>

        </ScrollContainer>

            </Box>
    )
}

Footer.propTypes = {

}