import { Button, Fade, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import intro01 from '../../res/images/dummy/intro_01.jpg';
import { COOKIE_PREF_VALUE_ALL, COOKIE_PREF_VALUE_ESSENTIAL } from './cookieConst';
import Grid from '@mui/material/Grid';
import { CheckboxCheckedIcon, CheckboxIcon } from '../utils/Accordion';

export const CookieLabel = styled((props) => (
    <FormControlLabel {...props} />
  ))(({ theme }) => ({
    justifyContent: 'space-between',
    [theme.breakpoints.up('xs')]: {
        width: `calc(100% - ${theme.spacing(1)})`,
        backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
        width: 'unset',
    },
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    '& .MuiFormControlLabel-label': {
            textTransform: 'uppercase',
            fontFamily: theme.typography.secondary.fontFamily,
            fontSize: 22,
            lineHeight: '.8',
            '&:hover' : {
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textUnderlineOffset: '5px',
            }
        },
  }));

export const CookieRadio = styled((props) => (
    <Radio checkedIcon={<CheckboxCheckedIcon />} icon={<CheckboxIcon />} {...props} />
  ))(({ theme }) => ({
    padding: theme.spacing(0),
    marginRight: theme.spacing(3),      
  }));

export default function CookiePage(props) {
    const [bannerContent, setBannerContent] = useState(null);
    const [cookiePref, setCookiePref]     = useState(COOKIE_PREF_VALUE_ALL);
    const [contentReady, setContentReady]   = useState(false);
    const [buttonTextAll, setButtonTextAll] = useState("Accept all");
    const [buttonTextEssential, setButtonTextEssential] = useState("Only necessary");
    const [buttonTextSave, setButtonTextSave] = useState("Save");
    const [bannerTitle, setBannerTitle] = useState("Cook");

    React.useEffect(() => {

        //1969 = COOKIE BANNER
        axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/pages/1969')
           .then(res => {
               setBannerContent(res.data.content.rendered);
               setBannerTitle(res.data.acf.banner_title);
               setButtonTextEssential(res.data.acf.button_essential);
               setButtonTextAll(res.data.acf.button_accept_all);
               setButtonTextSave(res.data.acf.button_save);
        })
           .catch(err => console.log(err));
        return () => {
            console.log("unmounted");          
        };
      }, []);

    const handleClickedButton = () => {
        console.log("handle clicked button")
        if(props.onSubmit) {
            props.onSubmit(cookiePref);
        }
    }

    const handleCookieSelectChange = (event) => {
        setCookiePref(event.target.value);
    }

    return(
        <Box sx={[{
            height:'100vh',
            width:'100%',
            position: 'fixed',
            zIndex:1000,
            backgroundColor: 'white',
            overflow: 'scroll',
        },
        ]}>
            <Fade in={bannerContent !== null}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{
                        height: {
                            xs: '0vh',
                            md: '50vh',
                        }
                    }}>
                    </Grid>
                    
                    <Grid   item
                            container
                            xs={12}
                            sx={{
                                backgroundColor: (theme) => theme.palette.secondary.main,
                            }}
                        >
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={10}>
                            <Typography sx={{
                                textAlign:'left',
                                textTransform: 'uppercase',
                                lineHeight: '.8',
                            }}
                            variant="h3"
                            >
                            {bannerTitle}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid   item
                            container    
                            xs={12}
                            sx={{
                                marginBottom: (theme) => theme.spacing(6),
                        }}>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={10}>
                            <Typography sx={{
                                textAlign:'left',
                                fontWeight: '400',
                                fontSize: 18,
                            }}
                                        variant='body2'
                                        dangerouslySetInnerHTML={{__html: bannerContent}}
                            >
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} sx={{
                        backgroundColor: (theme) => ({sx: theme.palette.primary.light, md: theme.palette.secondary.main }),
                        marginBottom: (theme) => theme.spacing(9),
                    }}>
                        <Grid item xs={0} md={2} />
                        <Grid item xs={12} md={10}>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={cookiePref}
                                onChange={handleCookieSelectChange}
                                sx={{
                                    flexDirection:{xs: 'column', md: 'row' },
                                    justifyContent:'space-between',
                                    width:{ xs: '100%', md: '75%'},
                                    gap: (theme) => ({xs: theme.spacing(3), md: theme.spacing(0)}),
                                }}
                            >
                                <CookieLabel value={COOKIE_PREF_VALUE_ALL} control={<CookieRadio /> } label={buttonTextAll} />
                                <CookieLabel value={COOKIE_PREF_VALUE_ESSENTIAL} control={<CookieRadio />} label={buttonTextEssential} />
                                <Button variant="text"
                                        onClick={handleClickedButton}
                                        sx={{
                                            // padding: (theme) => theme.spacing(0),
                                            textTransform: 'uppercase',
                                            fontSize: 22,
                                            fontFamily: (theme) => theme.typography.secondary.fontFamily,
                                            lineHeight: '.8',
                                            justifyContent: 'flex-end',
                                            borderRadius: 0,
                                            width: {xs: '100%', md:'unset'},
                                            backgroundColor: (theme) => theme.palette.secondary.main,
                                            padding: '3px 0px 3px 0px',
                                            '&:hover' : {
                                                transition: 'unset',
                                                backgroundColor: 'unset',
                                                textDecoration: 'underline',
                                                textDecorationThickness: '2px',
                                                textUnderlineOffset: '5px',
                                            }
                                        }}
                                >
                                    {buttonTextSave}
                                </Button>
                            </RadioGroup>
                        </Grid>
                    </Grid>

                </Grid>
            </Fade>
        </Box>
    )
}
