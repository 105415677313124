import { Box } from '@mui/system';
import React, {useEffect, useState} from 'react';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import GridPage from '../gridPage/gridPage';
import IntroPage from '../intro/introPage';
import MainNav from '../navigation/mainNav';
import TagNav from '../navigation/tagNav';
import WorkPage from '../workPage/workPage';
import AboutPage from '../aboutPage/aboutPage';
import CookiePage from '../cookiePage/cookiePage';
import { useCookies } from 'react-cookie';
import { COOKIE_PREF_KEY, COOKIE_PREF_VALUE_ALL, COOKIE_PREF_VALUE_ESSENTIAL } from '../cookiePage/cookieConst';
import axios from 'axios';
import ListPage from '../listPage/listPage';
import IndexPage from '../listPage/indexPage';
import Footer from '../navigation/footer';
import { Fade } from '@mui/material';
import StudioPage from '../listPage/studioPage';
import { isMobile } from 'react-device-detect';
import CookieBanner from '../cookiePage/cookieBanner';

export default function MainPage(props) {
    const [scrolledOut, setScrolledOut] = useState(false);
    const [introExited, setIntroExited] = useState(false);
    const [offsetY, setOffsetY]         = useState(0);
    const [selectedTag, setSelectedTag] = useState("");
    const [cookies, setCookie, removeCookie] = useCookies();
    const [works, setWorks] = useState([]);
    const [tags, setTags]   = useState([]);
    const location = useLocation();
    const [showMainNav, setShowMainNav] = useState(false);
    const params = useParams();
    const [offsetFix, setOffsetFix] = useState(0);
    const [pageReady, setPageReady] = useState(false);
    const [showCookiePref, setShowCookiePref] = useState(false);
    let navigate = useNavigate();


    useEffect(() => {
        axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/works?_embed&per_page=100')
        .then(res => {
            setWorks(res.data);
            console.log("Works: "+ res.data.length);
            setTags(res.data[0].taggard.allTags);
        })
        .catch(err => console.log(err));
        const scrollHandler = () => {
            if(window.scrollY > window.innerHeight*2 || introExited) {
                if(!showMainNav) {
                    setShowMainNav(true);
                    setSelectedTag("latest");
                }
            } else{
                setShowMainNav(false);
            }
        }
        if(location.pathname !== '/') {
            console.log("Pathname isn't /!");
            window.removeEventListener('scroll', scrollHandler);
            setShowMainNav(true);
        } else {
            window.addEventListener('scroll', scrollHandler);
        }

        if(location === "/index") {
            setSelectedTag("index");
        }

        return function cleanup() {
            window.removeEventListener('scroll', scrollHandler);
        }

        
      }, [location, introExited,offsetFix, showMainNav])

    const onClickedTagNavButton = (tag, route) => {
        console.log("Selected: " + tag, " Previous: " + selectedTag);
        window.setTimeout(() => {window.scrollTo(0,0);},0);
        if(selectedTag === tag && route === "works") {
            //if tag was previously selected, deselect it
            setSelectedTag(null);
            console.log("Deselected tags");
            navigate("/works");
        } else {
            setSelectedTag(tag);
            console.log("selected tag " + tag);
            navigate(route);

        }
    }

    const onCookieBannerSubmit = (cookiePref) => {
        setShowCookiePref(false);
        setCookie(COOKIE_PREF_KEY, cookiePref);
        
    }

    const onIntroExitHandler = () => {
        let scrollY = Math.max((window.scrollY - (window.innerHeight * 3)), 0);
        if(isMobile) {
            setTimeout(() => {window.scrollTo(0,scrollY);},0);
        } else {
            window.scrollTo(0,scrollY);
        } 
        setIntroExited(true);
    }

    const handleTagNavReady = () => {
        if(works) {
            setPageReady(true);
        }
    }

    const handleIndexPageItemSelect = (item) => {
        console.log("Index item " + item);
        if(item.taggard) {
            setSelectedTag(item.taggard.checkedTags[0]);
        }
    }

    return(
        <Fade in={pageReady}>
            <Box>
                {showCookiePref && <CookiePage onSubmit={onCookieBannerSubmit} />}
                {(cookies.cookiePref === undefined) && <CookieBanner onClickAccept={() => onCookieBannerSubmit(COOKIE_PREF_VALUE_ALL)} onClickEdit={() => setShowCookiePref(true)} />}
                <Box className="nav"
                    sx={{
                        position: 'fixed',
                        width: '100vw',
                        top: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        zIndex: 10,
                    }}
                    component="nav"
                >
                    <TagNav onTagsReady={handleTagNavReady} tags={tags} selected={selectedTag} onClickedNavButton={onClickedTagNavButton} />
                    <MainNav hidden={!showMainNav} selected={selectedTag} onClickedNavButton={onClickedTagNavButton} />
                </Box>
                <Box id="content"
                    component="main"
                >
                    <Routes>
                        <Route path="/" element={<GridPage works={works} selectedTag={selectedTag}/>}>
                            <Route index element={!introExited && <IntroPage onExitView={onIntroExitHandler} post={works && works[Math.floor(Math.random()*works.length)]} />}/>
                            <Route path="works" element={<></>} />    
                            <Route path="works/:work" element={<WorkPage works={works} />} />    
                        </Route>
                        <Route path="about" element={<AboutPage />} />
                        <Route path="studio" element={<StudioPage />} />
                        <Route path="contact" element={<ListPage postType='contact' title="Contact" />} />
                        <Route path="imprint" element={<ListPage postType='imprint' title="Imprint/Policy" />} />
                        <Route path="index" element={<IndexPage onSelectItem={handleIndexPageItemSelect} postType='works' title="Index" />} />
                    </Routes>
                </Box>
                <Footer />
            </Box>

        </Fade>
    )
}