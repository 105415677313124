import { useParams } from 'react-router-dom';
import './App.css';
import MainPage from './components/mainPage';
import TagNav from './components/navigation/tagNav';



function App() {
  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
