import React, {useEffect} from 'react';
import { Box, styled, ThemeProvider } from '@mui/system';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { Fade, Link } from '@mui/material';
import theme from '../../theme';

const buttonVerticalPadding = 1;

export const NavLink = styled((props) => {
    const { selected, ...other } = props;
    return <Button variant="text" {...other} />;
  })(({ theme, selected }) => ({
        border:'2px solid',
        textTransform: 'uppercase',
        textDecoration: 'none',
        fontFamily: theme.typography.secondary.fontFamily,
        fontSize: 16,
        borderColor: theme.palette.primary.main,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: `${buttonVerticalPadding} ${theme.spacing(1)} ${buttonVerticalPadding} ${theme.spacing(1)}`,
        borderRadius: selected ? 25 : 10,
        backgroundColor: selected ? theme.palette.secondary.main : theme.palette.primary.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        }
  }));
  

export default function MainNav(props) {

    const clickedNavButton = (el) => {
        console.log("Clicked navbutton " + el);
        if(props.onClickedNavButton) {
            props.onClickedNavButton(el, el);
        }
    }

    useEffect(() => {
        console.log("Selected is " + props.selected);
      }, [props.selected])

    return (
        <Fade in={!props.hidden}>
            <Box sx={[{
                width: '100%',
                backgroundColor: (theme) => theme.palette.primary.light,
                paddingBottom: (theme) => theme.spacing(3),
                textAlign: 'left',
                display: 'flex',

            }
            ]}>
                <NavLink selected={props.selected === "about"}  onClick={() => clickedNavButton("about")}>Barbara Herold</NavLink>
                <NavLink selected={props.selected === "studio"} onClick={() => clickedNavButton("studio")}>Studio       </NavLink>
            </Box>

        </Fade>
    )
}

MainNav.propTypes = {

}