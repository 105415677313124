import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Collapse, Fade, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, Link, useMediaQuery, useTheme } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, ExpandIcon, ExpandMore } from '../utils/Accordion';
import axios from 'axios';
import './indexPage.css';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { BhBodyType } from '../utils/BhTypography';
import {decode} from 'html-entities';

const BORDER_THICKNESS = 2;

const FlexibleImageListItemBar = styled(ImageListItemBar)(({ theme }) => ({
  '& 	 .MuiImageListItemBar-title,.MuiImageListItemBar-subtitle': {
    whiteSpace: 'unset',
  }
}));


function CollapsibleEntry(props) {
  const [expanded, setExpanded] = React.useState(false);

  const onClickExpander = () => {
    setExpanded(!expanded);
  }
  return (
    <Collapse in={expanded}
              collapsedSize={props.collapsedSize || 0}
              onClick={() => { if(!expanded) onClickExpander();}}
              sx={[
                {
                  borderBottom: (theme) => `none`,
                  '&:hover' : {
                    cursor: expanded ? 'unset' : 'pointer',
                  }
                },
                props.last && {
                  borderBottom: (theme) => `${BORDER_THICKNESS}px solid ${theme.palette.primary.main}`,
                },
                
              ]}>
              <Grid container sx={{
                border:'none',
                borderTop: (theme) => `${BORDER_THICKNESS}px solid ${theme.palette.primary.main}`,
                paddingTop: (theme) => theme.spacing(1)
              }}>
                <Grid item
                      container
                      xs={12}
                      md={2}
                      onClick={onClickExpander}
                      sx={{
                        '&:hover' : {
                            cursor: 'pointer',
                          }
                      }}
                >    
                  <Grid item xs={10} md={12}>
                    {props.title}            
                  </Grid>
                  <Grid item xs={2} md={0}>
                  <ExpandMore
                    expand={expanded}
                    onClick={onClickExpander}
                    aria-expanded={expanded}
                    aria-label="show more"
                    sx={{display: {xs: 'block', md: 'none'}}}
                  >
                    <ExpandIcon sx={{fontSize: '3rem',}} />
                  </ExpandMore>
                  </Grid>
                </Grid>
                <Fade in={expanded}>
                  <Grid item
                        xs={12}
                        md={9}
                        sx={{
                          
                        }}
                  >
                    {props.children}
                  </Grid>

                </Fade>
                <Grid item container direction="column" xs={1}>
                <ExpandMore
                  expand={expanded}
                  onClick={onClickExpander}
                  aria-expanded={expanded}
                  aria-label="show more"
                  sx={{display: {xs: 'none', md: 'block'}}}
                >
                  <ExpandIcon sx={{fontSize: '3rem',}} />
                </ExpandMore>
                </Grid>
              </Grid>
    </Collapse>
  )
}


export default function IndexPage(props) {
  const [expanded, setExpanded] = React.useState(0);
  const [listContent, setlistContent] = React.useState([]);
  const muiTheme = useTheme();
  const mdUp = useMediaQuery(muiTheme.breakpoints.up('md'));
  const lgUp = useMediaQuery(muiTheme.breakpoints.up('lg'));

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    //APPS
    if(props.postType) {
            axios.get(`https://barbaraherold.net/wordpress/wp-json/wp/v2/${props.postType}?_embed&per_page=100`)
            .then(res => setlistContent(res.data))
            .catch(err => console.log(err));
        }
    }, [props.postType]);

  const clickHandler = (entry) => {
      scroll.scrollToTop();
      console.log("Slected index item");
      if(props.onSelectItem) {
        props.onSelectItem(entry);
      }        
  }

  const getResponsiveCols = () => {
    let cols = 2;
    if(mdUp) {
      cols = 3;
      console.log("Mdup!");
    }
    if(lgUp) {
      cols = 5;
      console.log("lgUp!");
    }
    return cols;
  }

  const populateListContent = () => {
      let content = [];
      //FIRST: create array with all the years, sort descending
      //SECOND: add posts to array where year matches
      //THIRD: map list of year-post-objects 
      if(listContent.length > 0) {
          let years = listContent.map((entry) =>
            entry.acf.year || "")
          years = [...new Set(years)];
          years = years.sort((a,b) => b-a);
          console.log("Years: " + years);
          let mapped = years.map((year,index) => {
            let entry = { year: year, entries: [] };
            for(let c of listContent) {
              if(c.acf.year === year) {
                entry.entries.push(c);
              }
            }
            return entry;
          })
          content = mapped.map((year, index) => 
            <Grid item xs={12} key={index}>
              <CollapsibleEntry
                  collapsedSize={'80px'}
                  last={index === mapped.length - 1}
                  title={<Typography variant="h3" sx={{ textAlign: 'left'}}>{year.year}</Typography>}
              >
                <Box sx={{minHeight:'20vh', width:'100%'}}>
                    <ImageList variant="masonry" cols={getResponsiveCols()} gap={8}>
                        {year.entries.map((entry, index) => {
                          const src = entry.patchwork_entries.length > 0 && entry.patchwork_entries[0].imageUrl;
                          return(<Link onClick={() => clickHandler(entry)} key={index} component={RouterLink} to={"/works/"+entry.slug || "/"} sx={{
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'none',
                                    }
                                }}><ImageListItem key={decode(entry.title.rendered)}>
                                      <img
                                        src={src}
                                        srcSet={src}
                                        alt={decode(entry.title.rendered)}
                                        className="indexImg"
                                        loading="lazy"
                                      />
                                    <FlexibleImageListItemBar
                                      title={<Typography variant="h5">{decode(entry.title.rendered)}</Typography>}
                                      subtitle={<BhBodyType>{decode(entry.acf.description)}</BhBodyType>}
                                      sx={{
                                        whiteSpace:'unset',
                                      }}
                                      position="below"
                                    />
                                  </ImageListItem>
                          </Link>)
                        })}
                    </ImageList>
                </Box>
              </CollapsibleEntry>      
            </Grid>
          );
      }
      return content;
  }
  


  return (
    <Fade in={listContent.length > 0}>
      <Grid container
            spacing={0}
            sx={{
                marginTop: (theme) => theme.spacing(15),
                minHeight: '100vh',
                paddingLeft: (theme) => [theme.spacing(0), theme.spacing(1)],
            }}

      >
          <Grid   item
                  container xs={12}
                  spacing={0}
                  sx={{
                      alignContent: 'flex-start',
                  }}
          >
              {populateListContent()}
          </Grid>
      </Grid>
    </Fade>
  );
}