import { Button, Typography } from '@mui/material';
import { Box, flexbox } from '@mui/system';
import React, {useEffect, useState} from 'react';

export default function CookieBanner(props) {

    const handleEdit = () => {
        if(props.onClickEdit) {
            props.onClickEdit();
        }
    }

    const handleAccept = () => {
        if(props.onClickAccept) {
            props.onClickAccept();
        }
    }

    return (<Box sx={{
                        position:'fixed',
                        width: '100%',
                        height: 'auto',
                        bottom: 0,
                        backgroundColor: 'white',
                        zIndex: '100',
                        display: 'flex',
                        justifyContent: 'space-between',
    }}>
        <Typography>This website uses cookies to ensure you get the best experience on our website. <Button onClick={handleEdit} variant="text" sx={{ fontFamily: (theme) => theme.typography.primary.fontFamily, textTransform: 'none', textDecoration: 'underline' }}>Learn more.</Button></Typography>
        
        <Button onClick={handleAccept} variant="text" sx={{marginRight: (theme) => theme.spacing(3), textDecoration: 'underline', fontFamily: (theme) => theme.typography.primary.fontFamily,}}>OK!</Button>
    </Box>)
}