import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Fade, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary } from '../utils/Accordion';
import axios from 'axios';
import {decode} from 'html-entities';

const BodyText = styled((props) =>(<Typography variant="body2" {...props}>{props.children}</Typography>))(({ theme }) => ({
  fontSize:'1.5rem',
}));

export default function AboutPage(props) {
  const [expanded, setExpanded] = React.useState('panel1');
  const [aboutContent, setAboutContent] = React.useState('');
  const [cvContent, setCvContent] = React.useState({title:'', content:''});
  const [collectionsContent, setCollectionsContent] = React.useState({title:'', content:''});
  const [exhibitionsContent, setExhibitionsContent] = React.useState({title:'', content:''});
  const [aboutReady, setAboutReady] = React.useState(false);
  const [cvReady, setCVReady] = React.useState(false);
  const [collectionsReady, setCollectionsReady] = React.useState(false);
  const [exhibitionsReady, setExhibitionsReady] = React.useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    //1948 = ABOUT
    axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/pages/1948')
       .then(res => {setAboutContent(res.data.content.rendered); setAboutReady(true)})
       .catch(err => console.log(err));
    //1951 = CV
    axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/pages/1951')
      .then(res => {setCvContent(res.data); setCVReady(true);})
      .catch(err => console.log(err));
    //1953 = SAMMLUNGEN
    axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/pages/1953')
      .then(res => {setCollectionsContent(res.data); setCollectionsReady(true);})
      .catch(err => console.log(err));
    //1955 = Ausstellungen
    axios.get('https://barbaraherold.net/wordpress/wp-json/wp/v2/pages/1955')
      .then(res => {setExhibitionsContent(res.data); setExhibitionsReady(true);})
      .catch(err => console.log(err));
  }, []);

  


  return (
    <Grid container
          spacing={0}
          sx={{
              marginTop: (theme) => theme.fixedMargins.top,
              minHeight: '100vh',
          }}
    >
        <Grid item xs={0} md={3}>

        </Grid>
        <Fade timeout={500} in={aboutReady && cvReady && collectionsReady && exhibitionsReady}>
          <Grid item xs={12} md={9}>
              <Box sx={{
                textAlign: 'left',
                marginBottom: (theme) => theme.spacing(6),
              }}>
                  <Typography variant="h3" sx={{
                    width: '95%',
                    letterSpacing: '0.01071em',
                  }}
                    dangerouslySetInnerHTML={{__html: aboutContent}}
                  >
                  
                  </Typography>
              </Box>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography variant="h3">{decode(cvContent.title.rendered)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <BodyText
                          dangerouslySetInnerHTML={{__html: decode(cvContent.content.rendered)}}
              >
              </BodyText>
              </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography variant='h3'>{decode(collectionsContent.title.rendered)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <BodyText
                          dangerouslySetInnerHTML={{__html: decode(collectionsContent.content.rendered)}}>
              </BodyText>
              </AccordionDetails>
          </Accordion>
          <Accordion last expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Typography variant="h3">{decode(exhibitionsContent.title.rendered)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <BodyText
                          dangerouslySetInnerHTML={{__html: decode(exhibitionsContent.content.rendered)}}
              >
              </BodyText>
              </AccordionDetails>
          </Accordion>
          </Grid>
        </Fade>
    </Grid>
  );
}