import { Stack } from '@mui/material';
import * as React from 'react';
import ListPage from './listPage';


export default function StudioPage(props) {

  return (
    <Stack sx={{marginBottom: (theme) => theme.spacing(6)}}>
        <ListPage postType='news'  spacing={0} collapsedSize={0} title="News" />
        <ListPage postType='apps' collapsedSize={50} title="Apps" />
        <ListPage postType='texte' spacing={0} title="Texts" />
    </Stack>
  );
}