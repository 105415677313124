import { Fade, Grid, Paper, Stack } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, {useEffect} from 'react';
import { Link, Outlet } from 'react-router-dom';
import WorkEntry from './workEntry';
import { animateScroll as scroll } from 'react-scroll';
import {decode} from 'html-entities';

const Item = styled(Grid)(({ theme }) => ({
    maxWidth: '100%',
    flexGrow: 1,
  }));

export default function GridPage(props) {
    const [works, setWorks] = React.useState([]);

    React.useEffect(() => {
        //prepare the works
        if(props.works && props.works.length > 0) {
            //do the thing
            if(props.selectedTag) {
                let filteredWorks = props.works.filter(entry =>
                    entry.taggard.checkedTags && entry.taggard.checkedTags.includes(props.selectedTag)
                )
                setWorks(filteredWorks);
            } else {
                setWorks(props.works);
            }
        }
    }, [props.works, props.selectedTag]);

    const populateGridPage = (col) => {
        let populated = [];
        for(let i = col; i < works.length;i+=4) {

            works[i].patchwork_entries.length > 0 && populated.push(
                <WorkEntry 
                    key={works[i].id}
                    target={`works/${works[i].slug}`}
                    thumb={works[i].patchwork_entries[0].imageUrl}
                    title={decode(works[i].title.rendered)}
                    year={works[i].acf.year}
                    content={decode(works[i].acf.description)}
                />
            );
        }
        
        return populated;
    }

    return(
        <Box>
            <Outlet />
            <Fade in={works.length > 0} timeout={800}>
                <Box sx={{
                    marginTop: (theme) => theme.spacing(15),
                    marginBottom: (theme) => theme.spacing(3),
                }}>
                    <Grid container spacing={3} columns={16} sx={{
                            overflow: 'hidden',
                            display: ['none', 'flex']
                        }}>
                            {/*-- COL 1 */}
                            <Item item xs={12} sm={4}>
                                <Stack spacing={2}>
                                    {populateGridPage(0)}
                                </Stack>
                            </Item>
                            {/*-- COL 2 */}
                            <Item item xs={12} sm={5}>
                                <Stack spacing={2}>
                                    {populateGridPage(1)}
                                </Stack>
                            </Item>
                            {/*-- COL 3 */}
                            <Item item xs={12} sm={3}>
                                <Stack spacing={2}>
                                    {populateGridPage(2)}
                                </Stack>
                            </Item>
                            {/*-- COL 4 */}
                            <Item item xs={12} sm={4}>
                                <Stack spacing={2}>
                                    {populateGridPage(3)}
                                </Stack>
                            </Item>
                        </Grid>
                        <Stack spacing={2} sx={{
                            display: ['flex', 'none'],
                        }}>
                            {works.map((work, index) => (
                                <WorkEntry 
                                    key={work.id}
                                    target={`works/${work.slug}`}
                                    thumb={work.patchwork_entries[0].imageUrl}
                                    title={decode(work.title.rendered)}
                                    year={work.acf.year}
                                    content={decode(work.acf.description)}
                                />
                            ))}
                        </Stack>
                </Box>
            </Fade>

        </Box>
        
    )
}