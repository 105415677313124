import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Fade, Grid, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary } from '../utils/Accordion';
import axios from 'axios';
import { BhBodyType } from '../utils/BhTypography';
import {decode} from 'html-entities';

export default function ListPage(props) {
  const [expanded, setExpanded] = React.useState(0);
  const [listContent, setListContent] = React.useState([]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    //APPS
    if(props.postType) {
            axios.get(`https://barbaraherold.net/wordpress/wp-json/wp/v2/${props.postType}?_embed&per_page=100`)
            .then(res => setListContent(res.data))
            .catch(err => console.log(err));
        }
    }, [props.postType]);

    console.log("Props.spacing: " + props.spacing);

  const populateListContent = () => {
      let content = [];
      if(listContent.length > 0) {
          content = listContent.map((entry, index) => 
            (index > 0 && <Grid item container xs={12} key={index} spacing={3} rowSpacing={props.spacing ?? 3}>
                <Grid item xs={1} sm={2} lg={1} sx={{ display: ['none', 'block']}}>{entry.acf && <img style={{maxWidth:'100%'}} src={entry.acf.logo} alt={decode(entry.title.rendered)} />}</Grid>
                <Grid item xs={12} sm={10} lg={11}>
                    <Accordion TransitionProps={{
                        collapsedSize: props.collapsedSize || 0,
                    }} last={index === listContent.length - 1} expanded={expanded === index} onChange={handleChange(index)}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography variant="h3">{decode(entry.title.rendered)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Fade in={props.preview ? true : (expanded === index)}>
                                <Stack sx={{paddingLeft: (theme) => [theme.spacing(1), 0]}}>
                                    <Box sx={{
                                        display: ['block','none'],
                                        maxWidth: '50%',
                                        margin: 'auto',
                                    }}>
                                        {entry.acf && <img style={{maxWidth:'100%'}} src={entry.acf.logo} alt={decode(entry.title.rendered)} />}
                                    </Box>
                                    <BhBodyType
                                                dangerouslySetInnerHTML={{__html: decode(entry.content.rendered)}}
                                    >
                                    </BhBodyType>
                                </Stack>
                            </Fade>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>)
          );
      }
      return content;
  }
  


  return (
    <Fade in={listContent.length > 0}>
        <Grid container
            spacing={0}
            sx={{
                marginTop: (theme) => theme.spacing(15),
                minHeight: props.minHeight || 0,
            }}
        >
            <Grid item xs={12} md={2}>
                <Box sx={{
                    textAlign: 'left',
                    textTransform: 'uppercase',
                    paddingLeft: (theme) => theme.spacing(1),
                }}>
                    <Typography variant="h3">{props.title || ""}</Typography>
                </Box>
            </Grid>
                <Grid   item
                        container xs={12}
                        md={10}
                        spacing={0}
                        sx={{
                            alignContent: 'flex-start',
                        }}
                >
                    <Grid item xs={1} sx={{display: ['none','block']}}></Grid>
                    <Grid item xs={11}>
                        <Box sx={{
                        textAlign: 'left',
                        marginBottom: (theme) => theme.spacing(0),
                        paddingLeft: (theme) => [theme.spacing(1), 0],
                        }}>
                        <Typography variant="h3" sx={{
                            width: '95%',
                        }}
                            dangerouslySetInnerHTML={{__html: listContent.length > 0 && decode(listContent[0].content.rendered)}}
                        >
                        
                        </Typography>
                        
                        </Box>

                    </Grid>
                    {populateListContent()}
                </Grid>

        </Grid>
    </Fade>
  );
}